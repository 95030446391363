import React from "react";
import PropTypes from "prop-types";
import LabeledSelect from "./LabeledSelect";
import {isObject, isNil, get, cloneDeep, isEqual} from "lodash";

export const SortKey = {ADDRESS: 'DevAdr'};

export default class ReduxLabeledSelect extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            options: []
        }
    }

    setInitialValue(props, {find = false, object = {}}) {
        const {options} = this.state;
        if (options && options.length > 0 && !props.input.value) {
            if (!find) this.onChange(options[0].value);
            else {
                const option = options.find((o) => o.value === object.value);
                this.onChange(option.value);
            }
        }
    }

    componentDidMount() {
        const {options, init, disableSort} = this.props;
        this.setState({
            options: disableSort ? options : this.sortValues(options)
        }, () => {
            if (isObject(init)) {
                this.setInitialValue(this.props, {find: true, object: init})
            } else if (init) {
                this.setInitialValue(this.props, {find: false});
            }
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!isEqual(this.props.options, nextProps.options)) {
            this.setState({
                options: nextProps.disableSort ? nextProps.options : this.sortValues(nextProps.options)
            });
        }
    }

    onChange = (value) => {
        this.props.input.onChange(value);
    };

    onBlur = (value) => {
        this.props.input.onBlur(value);
    };


    sortValues(options) {
        const {sortKey = "name"} = this.props;
        const opts = cloneDeep(options);
        return opts.sort((a, b) => {
            const value1 = sortKey !== "name" ? get(a.value, sortKey) : get(a, sortKey);
            const value2 = sortKey !== "name" ? get(b.value, sortKey) : get(b, sortKey);
            const check = (val) => (isNil(val) ? 0 : isObject(val) ? JSON.stringify(val) : val).toString();
            return check(value1).localeCompare(check(value2), undefined, {numeric: true, sensitivity: 'base'});
        });
    };

    render() {
        const {label, options, id, input: {value}, meta: {touched, error, warning}, required, disabled, clearButton, disableSort, onSearchChange, initialSearch, autofocus, placeholder} = this.props;
        return (
            <LabeledSelect
                required={required}
                label={label}
                options={disableSort ? options : this.sortValues(options)}
                onChange={v => this.onChange(v)}
                warning={warning ? warning : ""}
                onSearchChange={onSearchChange}
                placeholder={placeholder}
                initialSearch={initialSearch}
                autofocus={autofocus}
                id={id}
                value={value}
                error={touched && error ? error : ""}
                disabled={disabled}
                onBlur={this.onBlur} clearButton={clearButton}/>
        )
    }

}

ReduxLabeledSelect.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string, // nazwa pola z obiektu
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]).isRequired
    })).isRequired,
    id: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    clearButton: PropTypes.bool,
    disableSort: PropTypes.bool,
    onSearchChange: PropTypes.func
};
