import React from "react";
import PropTypes from "prop-types"
import "./_monochart.scss"
import {Area, AreaChart, ReferenceLine, ResponsiveContainer} from "recharts";
import _ from "lodash";

export default class MonoChart extends React.Component {


    render() {
        const {className, data, background, color, dataKey, width, height, selected} = this.props;
        let referenceLines = _.isArray(selected) ? selected : [selected];
        let formatedData = [];
        data && data.forEach(element => {
            formatedData.push({value: dataKey ? _.get(element, dataKey) : dataKey});
        });
        return (
            <React.Fragment>
                <ResponsiveContainer width={width} height={height} className={`fetura-monochart ${color} ${className}`}>
                    <AreaChart data={formatedData} className={`${background ? 'with-background' : ''}`}>
                        <Area isAnimationActive={false} type='linear' dataKey='value' fill='#dddddd' stroke={0}
                              fillOpacity={1}
                              connectNulls={true}/>
                        {
                            referenceLines.map(refLine => (
                                <ReferenceLine x={refLine.selected} stroke="green" label={{value: refLine.label, dx: 60}}
                                               isFront={true}/>
                            ))
                        }
                    </AreaChart>
                </ResponsiveContainer>
            </React.Fragment>
        );
    }

}

MonoChart.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "success", "info", "warning", "error", ""]),
    background: PropTypes.bool,
    dataKey: PropTypes.string.isRequired,
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    data: PropTypes.array.isRequired,
    selected: PropTypes.oneOf([PropTypes.shape({
        selected: PropTypes.number,
        label: PropTypes.string

    }), PropTypes.array]),
    selectedLabel: PropTypes.string
};

MonoChart.defaultProps = {
    className: "",
    data: [],
    dataKey: "",
    color: "",
    background: false,
    width: "100%",
    height: "100%",
    selected: []
};
