import React from "react";
import "./_required-text.scss";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

class RequiredText extends React.Component {

    render() {
        const {t} = this.props;
        return (
            <div className="required-container">
                <span><b>{t("basics.requiredText.title")}</b></span>
            </div>
        );
    }
}

export default compose(
    withTranslation()
)(RequiredText);

