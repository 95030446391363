import React, {Component} from 'react';
import PropTypes from 'prop-types';
import InfoBox from "../info-box/InfoBox";
import "./_qr-scanner.scss"
import QrCode from "qrcode-reader";
import LoadingComponent from "../../loading/LoadingComponent";
import {withTranslation} from "react-i18next";

class QrScanner extends Component {

    state = {
        permissionDenied: false,
        foundSource: false,
        error: false
    }
    video = React.createRef();
    canvas = React.createRef();
    interval = null;
    qr = new QrCode();
    lastValue = null;
    stream = null;

    componentDidMount() {
        this.qr.callback = this.qrCallback;
        if ("mediaDevices" in navigator) {
            navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: "environment",
                    focusMode: "continuous"
                }
            }).then(res => {
                this.stream = res;
                this.video.current.srcObject = res;
                this.setState({
                    foundSource: true
                });
            }).catch(e => {
                console.error(e, e.code, e.message);
                if (e.message === "Permission denied") {
                    this.setState({
                        permissionDenied: true,
                        foundSource: true
                    })
                } else {
                    this.setState({
                        foundSource: true,
                        error: true
                    })
                }
            })
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        if (this.stream) {
            for (let track of this.stream.getTracks()) {
                track.stop();
            }
        }
    }

    qrCallback = (err, value) => {
        if (value) {
            if (this.lastValue !== value.result) {
                this.lastValue = value.result;
                this.props.onFound(value.result);
            }
        }
    }

    analyzePhoto() {
        let width = this.video.current.videoWidth;
        let height = this.video.current.videoHeight;
        let context = this.canvas.current.getContext("2d");
        context.fillRect(0, 0, width, height);
        context.drawImage(this.video.current, 0, 0, width, height);
        let imageData = context.getImageData(0, 0, width, height);
        this.qr.decode(imageData);
    }

    onVideoLoadedMetadata = () => {
        this.canvas.current.width = this.video.current.videoWidth;
        this.canvas.current.height = this.video.current.videoHeight;
        this.interval = setInterval(() => {
            this.analyzePhoto();
        }, 500)
    }

    render() {
        const {permissionDenied, foundSource, error} = this.state;
        const {t} = this.props;
        return (
            <div className="fetura-qr">
                <LoadingComponent isLoading={!foundSource}/>
                {
                    permissionDenied && <InfoBox boxColor={"error"}>{t("qrScanner.permissionDenied")}</InfoBox>
                }
                {
                    error && <InfoBox boxColor={"error"}>{t("qrScanner.error")}</InfoBox>
                }
                {
                    !permissionDenied && !error &&
                    <>
                        <video autoPlay ref={this.video} onLoadedMetadata={this.onVideoLoadedMetadata}/>
                        <canvas ref={this.canvas}/>
                    </>
                }
            </div>
        );
    }
}

QrScanner.propTypes = {
    onFound: PropTypes.func.isRequired,
};

export default withTranslation()(QrScanner);