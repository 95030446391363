import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./_search-input.scss"
import {get} from "lodash";

class SearchInput extends Component {

    onChange = event => {
        const {onChange} = this.props;
        onChange(get(event, "target.value", ""));

    };

    onKeyUp = event => {
        const {value, onEnter, onClear} = this.props;
        console.log(event.keyCode);
        //enter
        if (event.keyCode === 13) {
            onEnter({value})
        } else if (event.keyCode === 27) {
            onClear({value})
        }
    }

    render() {
        const {value, placeholder, className, id} = this.props;
        const cName = ["fetura-search-input", className].filter(o => !!o).join(" ");
        return (
            <div className={cName}>
                <i className={"fas fa-search"}/>
                <input id={id} onKeyUp={this.onKeyUp} type={"text"} placeholder={placeholder}
                       onChange={this.onChange} value={value} autoFocus={true}
                />
            </div>
        );
    }
}

SearchInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onEnter: PropTypes.func,
    onClear: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string
};

SearchInput.defaultProps = {
    value: "",
    onChange: () => {
    },
    onEnter: () => {
    },
    onClear: () => {

    },
    placeholder: "",
    className: ""
};

export default SearchInput;
