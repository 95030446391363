import React from "react";
import PropTypes from "prop-types";
import Button from "../button/Button";
import _ from "lodash";

export default class MenuItem extends React.Component {

    render() {
        const {children} = this.props;
        return (
            <li>
                {
                    _.isObject(children) && children
                }
                {
                    !_.isObject(children) &&
                    <Button stopPropagation={false} {...this.props} type={"button"}
                            buttonColor={"secondary"}>{children}</Button>
                }
            </li>
        );
    }

}


MenuItem.propTypes = {
    onClick: PropTypes.func,
    icon: PropTypes.node,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};