import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import "./_mobile-app-link.scss"

class MobileAppLink extends Component {
    render() {
        const {name, href, state, selected, children} = this.props;
        return (
            <Link to={{pathname: href, state}} className={`fetura-mobile-link${selected ? " selected" : ""}`}>
                {name}
                {children}
            </Link>
        );
    }
}

MobileAppLink.propTypes = {
    name: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    state: PropTypes.object,
    selected: PropTypes.bool
};

export default MobileAppLink;