import React from "react";
import PropTypes from "prop-types"
import {myID} from "../../../../libs/generateID";
import Slider from "../Slider";

export default class LabeledSlider extends React.Component {

    render() {
        const {label, value, valueFormatter, required} = this.props;
        return (
            <React.Fragment>
                <label>{label}{required && <sup>*</sup>}
                    <strong style={{float: "right"}}>{valueFormatter(value)}</strong>
                </label>
                <Slider {...this.props}/>
            </React.Fragment>
        );
    }

}

LabeledSlider.propTypes = {
    label: PropTypes.string.isRequired,
    valueFormatter: PropTypes.func,
    onChange: PropTypes.func,
    id: PropTypes.string,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]).isRequired,
    isRange: PropTypes.bool,
    disabled: PropTypes.bool
};

LabeledSlider.defaultProps = {
    onChange: () => {
    },
    id: myID(),
    disabled: false,
    step: 1,
    min: 0,
    max: 100,
    valueFormatter: (value) => value
};