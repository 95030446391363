import React from "react";
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import "./_menu.scss"
import _ from "lodash"
import {bodyLockScroll, bodyUnlockScroll, ScrollLock} from "../../../utils/DOMUtils";
import {CSSTransition} from "react-transition-group";
import {BreakPoint} from "../../../constans/breakpointTypes";

export default class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: !!props.event
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.event && !_.isEqual(prevState.event, nextProps.event)) {
            return {
                isOpen: true,
                event: nextProps.event
            }
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isOpen !== this.state.isOpen) {
            if (this.state.isOpen) {
                document.addEventListener('click', this.onClickOutside);
            } else {
                document.removeEventListener('click', this.onClickOutside);
            }
        }
    }

    calculatePosition = () => {
        if (this.state.event) {
            const isButton = this.getSource();
            const helper = [[true, true], [false, true], [false, false], [true, false]];
            const fits = [false, false, false, false];
            const menuWidth = this.menu.clientWidth;
            const menuHeight = this.menu.clientHeight;
            const windowHeight = window.innerHeight;
            const windowWidth = window.innerWidth;
            const {clientY, clientX, pageX, pageY} = this.state.event;
            helper.forEach(([measureFromLeft, measureFromTop], i) => {
                const tmpWidth = measureFromLeft ? (clientX) : (windowWidth - clientX);
                const tmpHeight = measureFromTop ? (clientY) : (windowHeight - clientY);
                if ((menuWidth <= tmpWidth) && (menuHeight <= tmpHeight)) {
                    fits[i] = true;
                }
            });
            const isTooBig = !fits.reduce((a, b) => (+a + +b), 0);
            if (isTooBig || (windowWidth < BreakPoint.XS)) {
                bodyLockScroll(ScrollLock.MENU);
                this.menu.classList.add("small-device");
                this.menu.style.transform = '';
                this.menu.style.borderRadius = '0';
            } else {
                this.menu.classList.remove("small-device");
                for (let i of [2, 3, 1, 0]) {
                    if (fits[i]) {
                        if (!isButton) {
                            this.menu.style.transform = `translateX(${helper[i][0] ? pageX - menuWidth : pageX}px) translateY(${helper[i][1] ? pageY - menuHeight : pageY}px)`;
                        } else {
                            const {event: {nativeEvent: {clientX, clientY} = undefined} = undefined} = this.state;
                            this.menu.style.position = 'fixed';
                            this.menu.style.transform = `translateX(${clientX ? clientX - menuWidth : pageX}px) translateY(${clientY ? clientY - menuHeight : pageY}px)`;
                        }
                        this.menu.style.borderRadius = [i === 2 ? "0px" : "1rem", i === 3 ? "0px" : "1rem", i === 0 ? "0px" : "1rem", i === 1 ? "0px" : "1rem"].join(" ");
                        break;
                    }
                }
            }
        }
    };

    componentWillUnmount() {
        bodyUnlockScroll(ScrollLock.MENU);
        document.removeEventListener('click', this.onClickOutside);
    }

    onClickOutside = (event) => {
        bodyUnlockScroll(ScrollLock.MENU);
        if (event) {
            event.stopPropagation();
            event.preventDefault();
            event.stopImmediatePropagation();
            this.setState({
                isOpen: false
            });
        }
    };

    getSource = () => {
        const {event} = this.props;
        if (!!event && event.target && _.isArray(event.target.className)) {
            return !!(event.target.className.includes("fetura-button round info") || event.target.className.includes("fas fa-ellipsis-v"));
        }
    };

    render() {
        const {children} = this.props;
        const {isOpen} = this.state;
        return ReactDOM.createPortal((
            <CSSTransition in={isOpen} unmountOnExit onEnter={this.calculatePosition}>
                <div className={`menu-container`} ref={ref => this.menu = ref}>
                    <ul>
                        {children}
                    </ul>
                </div>

            </CSSTransition>
        ), document.getElementById("root") || document.createElement("div"))
    }

}

Menu.propTypes = {
    event: PropTypes.object,
    cornerRadius: PropTypes.bool
};

Menu.defaultProps = {
    cornerRadius: true
};