import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom"
import "./_printable.scss";
import logo from "../../../resources/images/logos/fetura_cloud_colour.svg"
import PrintableTableGrid from "../table-grid/printable-table-grid/PrintableTableGrid";
import {isMobile} from "../../../utils/MobileUtils";
import TableGrid from "../table-grid/TableGrid";

export default class Printable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            printing: false
        }
    }

    componentDidMount() {
        if (this.props.printing) {
            this.print();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.printing !== nextProps.printing && nextProps.printing) {
            this.print();
        }
    }

    componentWillUnmount() {
        this.setState({
            printing: false
        })
    }

    print() {
        this.setState({
            printing: true
        }, () => {
            setTimeout(() => {
                window.onafterprint = () => {
                    if (!isMobile()) {
                        setTimeout(() => {
                            this.setState({
                                printing: false
                            })
                        })
                    }
                };
                window.print();
                this.props.onPrintEnd();
            }, 1000)
        })
    }

    getPrintChildren() {
        let children = Array.isArray(this.props.children) ? this.props.children : [this.props.children];
        return children.map(child => {
            if (child.type === TableGrid) return <PrintableTableGrid {...child.props}/>;
            return child;
        })
    }

    render() {
        const {children, name, additionalText} = this.props;
        const {printing} = this.state;
        console.log(this.getPrintChildren());
        return (
            <>
                {children}
                {
                    printing &&
                    ReactDOM.createPortal(
                        <div className="printable">
                            <img src={logo} alt={"logo"}/>
                            <div className="printable-title">
                                <h2>{name}</h2>
                                {additionalText && <h5>{additionalText}</h5>}
                            </div>
                            {this.getPrintChildren()}
                        </div>,
                        document.getElementById("root")
                    )
                }

            </>
        );
    }

}

Printable.propTypes = {
    printing: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    onPrintEnd: PropTypes.func.isRequired,
    horizontal: PropTypes.bool,
    additionalText: PropTypes.node
};

Printable.defaultProps = {
    horizontal: false
}