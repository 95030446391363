import React from "react";
import {getRemToPx} from "../../../utils/DOMUtils";
import windowSize from 'react-window-size';
import PropTypes from "prop-types";
import _ from "lodash";

class SimpleCellMeasurer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            height: SimpleCellMeasurer.calculateHeight(this.props)
        }
    }

    static getHeight = (height) => {
        return _.isString(height) ? getRemToPx(parseInt(height, 10)) : _.isNumber(height) ? height : 150
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const newHeight = SimpleCellMeasurer.calculateHeight(nextProps);
        if (newHeight !== prevState.height) {
            return {height: newHeight}
        }
        return null;
    }

    static calculateHeight = ({windowWidth, breakPoints, defaultHeight}) => {
        breakPoints.sort((o1, o2) => o2.max - o1.max);
        let newHeight;
        for (let bp of breakPoints) {
            if (windowWidth <= bp.max) {
                newHeight = SimpleCellMeasurer.getHeight(bp.height || defaultHeight);
                break;
            }
        }
        return newHeight ? newHeight : SimpleCellMeasurer.getHeight(defaultHeight);
    };

    render() {
        const {children} = this.props;
        const {height} = this.state;
        return children({rowHeight: height || 150});
    }
}

SimpleCellMeasurer.propTypes = {
    breakPoints: PropTypes.array.isRequired,
    defaultValue: PropTypes.string,
    children: PropTypes.func.isRequired
};

SimpleCellMeasurer.defaultProps = {
    breakPoints: [],
    defaultValue: 150
};
export default windowSize(SimpleCellMeasurer);