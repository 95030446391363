import React from "react";
import Select from "../Select";
import PropTypes from "prop-types";

export default class LabeledSelect extends React.PureComponent {

    onChange = (value) => {
        this.props.onChange(value);
    };

    render() {
        const {options, label, error, warning, initialValue, required, value, disabled, clearButton, onBlur, onSearchChange, initialSearch, autofocus, placeholder} = this.props;
        return (
            <React.Fragment>
                <label>{label}{required && <sup>*</sup>}</label>
                <Select options={options} onChange={this.onChange} error={error} warning={warning}
                        initialValue={initialValue} onSearchChange={onSearchChange} initialSearch={initialSearch}
                        ref={ref => this.select = ref} value={value} disabled={disabled} clearButton={clearButton}
                        onBlur={onBlur} autofocus={autofocus} placeholder={placeholder}/>
            </React.Fragment>
        );
    }

}

LabeledSelect.propTypes = {
    label: PropTypes.node.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string, // nazwa pola z obiektu
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]).isRequired
    })).isRequired,
    onChange: PropTypes.func,
    onSearchChange: PropTypes.func,
    error: PropTypes.string,
    initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
    disabled: PropTypes.bool,
    clearButton: PropTypes.bool,
    onBlur: PropTypes.func
};

LabeledSelect.defaultProps = {
    onChange: () => {
    },
    onSearchChange: () => {
    }
};
