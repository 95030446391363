import React from "react";
import "./_loading.scss";

export class LoadingBull extends React.PureComponent{
    render() {
        const {size} = this.props;
        let className  = ["fetura-loading loading-bull"];
        className.push(`size-${size}`);
        return(
            <span className={className.join(" ")}>
                <div><i className="fas fa-circle"/></div>
                <div><i className="fas fa-circle"/></div>
                <div><i className="fas fa-circle"/></div>
            </span>
        )
    }
}

LoadingBull.defaultProps = {
    size: 1
};