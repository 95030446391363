import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./_resizable-jumbotron.scss";

//todo: zrobic jumbotron
class ResizableJumbotron extends Component {
    render() {
        const {expanded, color, text, icon, description, onClick} = this.props;
        const className = ["fetura-resizable-jumbotron", color, expanded ? "expanded" : ""].filter(o => !!o).join(" ");
        return (
            <div className={className}>
                <div className={"resizable-body"}>
                    <div>
                        {
                            !!icon &&
                            <i className={`opacity-75 pointer fa-fw mr-1 ${icon}`}/>
                        }
                        <strong className={"pointer"} onClick={onClick}>{text}</strong>
                        {
                            !!description &&
                            <>
                                {!expanded && <strong className={"ml-1 opacity-75 mr-1"}>|</strong>}
                                <span className={"desc opacity-75"}>{description}</span>
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

ResizableJumbotron.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    expanded: PropTypes.bool,
    description: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string
};

export default ResizableJumbotron;
