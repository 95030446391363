import Slider from './Slider';
import React from "react";
import PropTypes from "prop-types"
import {myID} from "../../../libs/generateID";

export default class ReduxSlider extends React.Component {

    onChange = value => {
        this.props.input.onChange(value)
    };

    render() {
        const {min, max, step, disabled, input: {value}, isRange, valueFormatter, tipFormatter} = this.props;
        return (
            <Slider tipFormatter={tipFormatter} valueFormatter={valueFormatter} min={min} max={max} step={step}
                    disabled={disabled} value={value}
                    isRange={isRange}
                    onChange={(v) => this.onChange(v)}/>
        );
    }

}

Slider.propTypes = {
    id: PropTypes.string,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]).isRequired,
    isRange: PropTypes.bool,
    disabled: PropTypes.bool,
    valueFormatter: PropTypes.func,
    tipFormatter: PropTypes.func
};

Slider.defaultProps = {
    onChange: (value) => {
    },
    id: myID(),
    disabled: false,
    step: 1,
    min: 0,
    max: 100
};
