import React from "react";
import PropTypes from "prop-types"
import "./_progresschart.scss"
import {getColorForPercentage} from "../../../utils/ColorUtils";

export default class ProgressChart extends React.Component {

    render() {
        const {className, values, color, labels, showLabels, showValue, skew} = this.props;
        const extraParams = (idx) => skew ? {textAnchor: "end", transform: `rotate(-45,${40 * idx},140)`} : {};
        return (
            <svg className={`fetura-progresschart ${className} ${color}`} viewBox={`0 0 ${40 * values.length} 160`}>
                {
                    showLabels && labels.map((label, index) => (
                        <text {...extraParams(index)} fill={"currentColor"} fillOpacity={0.5} x={(40 * index) + 15}
                              y={150}>{label}</text>
                    ))
                }
                {
                    values.map((percentage, index) => (
                        <>
                            <rect className={getColorForPercentage(percentage)} rx={5} ry={5} x={40 * index}
                                  y={120 - (Math.min(percentage, 100) || 1)} width="30"
                                  height={Math.min(percentage, 100) || 1}/>
                            {
                                showValue &&
                                <text fill={"currentColor"} x={40 * index}
                                      y={Math.max((120 - ((Math.min(percentage, 100) || 0))), 20)}>{percentage}%</text>

                            }
                        </>
                    ))
                }

            </svg>
        );
    }

}

ProgressChart.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "multi"]),
    className: PropTypes.string,
    values: PropTypes.array.isRequired,
    labels: PropTypes.array,
    showLabels: PropTypes.bool,
    showValue: PropTypes.bool,
    skew: PropTypes.bool
};

ProgressChart.defaultProps = {
    color: "multi",
    className: "",
    skew: false,
    labels: [],
    showLabels: false,
    showValue: false,
};
