import React, {Component} from 'react';
import PropTypes from "prop-types";
import {ReactSVG} from "react-svg";
import "./_icon-value-new.scss";
import Tooltip from "../tooltip/Tooltip";

const MemoSVG = React.memo(({style, icon}) => <ReactSVG
    style={style}
    className={"icon"}
    loading={() => <svg viewBox="0 0 270.93334 270.93334"/>}
    src={icon}
/>)

class IconValue extends Component {
    render() {
        const {icon, title, unit, value, valueFormatter, show, small, color} = this.props;
        const style = color ? {color} : undefined;
        return (
            <Tooltip tooltipContent={title}>
                <div className={"fetura-icon-value-new"} title={title}>
                    {
                        !!show &&
                        <>
                            <MemoSVG
                                style={style}
                                icon={icon}
                            />
                            <div

                                className={small ? "value-small" : "value"}
                            >
                                {valueFormatter(value) || "-"}{unit}
                            </div>
                        </>
                    }
                </div>
            </Tooltip>
        );
    }
}

IconValue.propTypes = {
    icon: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    showWhenValueNotFound: PropTypes.bool,
    disabledTooltip: PropTypes.bool,
}

IconValue.defaultProps = {
    valueFormatter: (value) => value,
    unit: ""
}

export default IconValue;
