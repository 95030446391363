import React from "react";
import PropTypes from "prop-types"

export default class ListItem extends React.Component {

    onContextMenu = e => {
        if (this.props.onContextMenu) {
            e.persist();
            e.preventDefault();
            this.props.onContextMenu(e);
        }
    };

    render() {
        const {className, children, selected, index, lp, color, disableHover, onClick, itemRef, showNumber} = this.props;
        let cName = "fetura-list-item";
        cName += className ? ` ${className}` : "";
        cName += selected ? " selected" : "";
        cName += showNumber ? " show-number" : "";
        cName += index !== undefined && index % 2 === 0 ? " odd" : " even";
        cName += disableHover ? " disable-hover" : "";
        return (
            <div {...this.props} ref={itemRef} className={cName} data-lp={lp} onContextMenu={this.onContextMenu}
                 onClick={onClick}>
                {children}
                {
                    !!color && <div className="fetura-list-item-color" style={{background: color}}/>
                }
            </div>
        );
    }

}

ListItem.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    selected: PropTypes.bool,
    style: PropTypes.object,
    color: PropTypes.string,
    disableHover: PropTypes.bool,
    onContextMenu: PropTypes.func,
    onClick: PropTypes.func,
    showNumber: PropTypes.bool
};

ListItem.defaultProps = {
    className: "",
    style: {}
};
