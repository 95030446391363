import React from "react";
import PropTypes from "prop-types"
import "./_list.scss"
import ListItem from "./list-item/ListItem";
import i18next from "i18next";

export default class List extends React.Component {

    static groupByProperty(elements, getKey) {
        const map = new Map();
        elements.forEach((item) => {
            const key = getKey(item);
            const collection = map.get(key);
            if (!collection) map.set(key, [item]);
            else collection.push(item);
        });
        return new Map([...map].sort((a, b) => +(a[0] === i18next.t("grid.rest")) - +(b[0] === i18next.t("grid.rest"))));
    }

    showGrouped = (list) => {
        let result = [];
        for (const [key, value] of list) {
            result.push(<div className="list-separator"><h5>{key}</h5></div>);
            result = [...result, ...value];
        }
        return result;
    };

    render() {
        const {selectable, striped, children, className, style, showNumber, id, useFlexLayout, itemRef, property} = this.props;
        let elements = children;
        const isReady = property && Array.isArray(children[0]);
        if (isReady) {
            elements = List.groupByProperty(children[0], (l) => l.props[property]);
        }
        let cName = "fetura-list";
        cName += selectable ? " selectable" : "";
        cName += striped ? " striped" : "";
        cName += showNumber ? " show-number" : "";
        cName += useFlexLayout ? " flex-layout" : "";
        cName += className ? " " + className : "";
        return (
            <div ref={itemRef} id={id} style={style} className={cName}>
                {
                    !isReady && elements
                }
                {
                    isReady && this.showGrouped(elements)
                }
            </div>
        );
    }

}

List.propTypes = {
    selectable: PropTypes.bool,
    striped: PropTypes.bool,
    id: PropTypes.string,
    showNumber: PropTypes.bool,//pokazuje przekazany numer jako lp props
    children: (props, propName, componentName) => {
        const prop = props[propName];
        let error = null;
        React.Children.forEach(prop, (child) => {
            if (child.type !== ListItem) {
                error = new Error('`' + componentName + '` children should be of type `ListItem`.');
            }
        });
        return error
    },
    className: PropTypes.string,
    useFlexLayout: PropTypes.bool,
    property: PropTypes.string,
};

List.defaultProps = {
    selectable: true,
    striped: true,
    className: "",
    style: {},
    useFlexLayout: true,
    property: ""
};
