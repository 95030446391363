import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types"
import "./_select.scss"
import Input from "../input/Input";
import {Fade} from "react-bootstrap";
import SelectItem from "./SelectItem";
import _ from "lodash";
import {isMobile} from "../../../utils/MobileUtils";
import Tooltip from "../tooltip/Tooltip";
import {withTranslation} from "react-i18next";

export class Select extends React.Component {

    constructor(props) {
        super(props);
        const {initialSearch} = props;
        this.state = {
            //wartosc szukana, initialSearch przydaje sie w przypadku gdy chcemy sobie dynamicznie zmieniac z inputa na selecta w naszym komponencieprzy zachowaniu spójności co do wartości
            search: initialSearch ? initialSearch : "",
            options: [], //opcje przekonwertowane na SelectItem
            menuOpened: false, //flaga czy menu jest otwarte,
            hovered: null //flaga, który element jest podświetlony
        };
        this.caret = React.createRef();
        this.mobileSelect = React.createRef();
    }

    componentDidMount() {
        const {search} = this.state;
        search !== "" ? this.onSearchChange(search) : this.generateOptions(); //wygenerowanie opcji na wejsciu do komponentu
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {options, value} = this.props;
        const {search} = this.state;
        //jesli zmienia sie opcje lub zostanie jakas wybrana generujemy na nowo komponenty od opcji
        if (!_.isEqual(options, nextProps.options) || !_.isEqual(value, nextProps.value)) {
            this.generateOptions(search, nextProps);
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {options, error, value} = this.props;
        if (!_.isEqual(options, nextProps.options)) return true;
        if (!_.isEqual(error, nextProps.error)) return true;
        if (!_.isEqual(value, nextProps.value)) return true;
        if (this.props.disabled !== nextProps.disabled) return true;
        return !_.isEqual(this.state, nextState);
    }

    onSearchChange = value => {
        this.setState({
            search: value,
            menuOpened: true
        });
        this.generateOptions(value);
        this.props.onSearchChange(value);
    };

    onHoverChange = index => {
        this.setState({
            hovered: index
        }, () => {
            this.generateOptions(this.state.search)
        })
    };

    filterOptions(options, search) {
        return options.filter(item => {
            let text = item.name ? _.get(item.value, item.name, item.name) : typeof item.value !== "object" ? item.value : "";
            text += "";
            return search ? text.toLowerCase().includes(search.toLowerCase()) || text.toLocaleLowerCase().includes("+") : true;
        });
    }

    generateOptions = (search = "", props = this.props) => {
        const {options, value} = props;
        const {hovered} = this.state;
        let opts = this.filterOptions(options, search);
        let array = opts.map((option, index) =>
            <SelectItem option={option} onSelect={this.onChange} key={index}
                        active={_.isEqual(option.value, value)} index={index} hovered={hovered === index}
                        onHoverChange={this.onHoverChange}/>
        );
        this.setState({
            options: array
        })
    };

    onChange = (option) => {
        if (option) {
            this.setState({
                menuOpened: false,
                hovered: null
            });
            this.props.onChange(option.value)
        } else {
            this.props.onChange(null)
        }
    };

    onExited = () => {
        this.generateOptions();
    };

    onExit = () => {
        this.setState({
            search: ""
        })
    };

    clearValue = () => {
        this.setState({
            placeholder: "",
            selectedOption: undefined
        })
    };

    getPlaceholder = () => {
        const {value, placeholder, options} = this.props;
        let selectedOption = options.find(item => _.isEqual(item.value, value));
        return selectedOption ? _.get(typeof selectedOption.value === 'object' ? selectedOption.value : selectedOption, selectedOption.name, selectedOption.name) : placeholder || "";
    };

    onMobileSelectChange = e => {
        let index = +e.target.value;
        let obj = this.props.options[index];
        this.onChange(obj);
    };

    onFocusInput = () => {
        this.setState({
            menuOpened: true
        })
    };

    onBlurInput = (e) => {
        if (e.relatedTarget !== this.caret.current && !this.selectContainer.contains(e.relatedTarget)) {
            this.setState({
                menuOpened: false,
                hovered: null
            })
        }
        this.props.onBlur(this.props.value);
    };

    onCaretClick = () => {
        if (!this.state.menuOpened) {
            let feturaInput = this.select.getElementsByClassName("fetura-input")[0];
            let input = feturaInput.getElementsByTagName("input")[0];
            input.focus();
        } else {
            this.setState({
                menuOpened: false
            })
        }
    };

    onRemoveValueClick = () => {
        this.onChange(null);
    };

    onKeyDownInput = e => {
        const {hovered, options} = this.state;
        if (options.length) {
            if (e.which === 38 || e.which === 40) {
                let hoveredIndex = 0;
                if (e.which === 40) {
                    hoveredIndex = hovered === options.length - 1 ? hovered : hovered === null ? 0 : hovered + 1;
                }
                if (e.which === 38) {
                    hoveredIndex = hovered ? hovered - 1 : 0;
                }

                this.onHoverChange(hoveredIndex);
                let children = this.selectContainer.children;
                let item = children[hoveredIndex];
                if (item) {
                    item.scrollIntoView({
                        block: "nearest"
                    });
                }
            }
            if (e.which === 13) {
                const {insertFirstOnNoSelected} = this.props;
                e.stopPropagation();//przydatne gdy select jest renderowany w modalu, i chcemu eneterem wybrac opcje
                let opts = this.filterOptions(this.props.options, this.state.search);
                this.onChange(opts[hovered] || (this.state.search ? opts[0] : insertFirstOnNoSelected ? opts[0] : null));
                if (this.select) {
                    let feturaInput = this.select.getElementsByClassName("fetura-input")[0];
                    let input = feturaInput.getElementsByTagName("input")[0];
                    input.blur();
                }
            }
            return false;
        }
        return true;
    };

    getMobileSelectValue() {
        const {value, options} = this.props;
        return _.findIndex(options, item => _.isEqual(value, item.value));
    }

    getSelectMenuClassName(isMobile, renderInPortal) {
        let className = "select-menu";
        if (isMobile) className += " mobile";
        if (!renderInPortal) className += " static";
        return className;
    }

    getPropertiesBasedOnParent = (element) => {
        if (element && element.parentNode) {
            const parentClassName = element.parentNode.className;
            if (element.parentNode && parentClassName) {
                if (parentClassName.match(new RegExp("view-container-modal", "g")) || parentClassName.match(new RegExp("modal show", "g"))) {
                    const parent = element.parentNode;
                    const parentRect = parent.getBoundingClientRect();
                    const selectRect = this.select.getBoundingClientRect();
                    return {
                        widthOfMenu: selectRect.width,
                        menuLeft: selectRect.left - parentRect.left,
                        menuTop: selectRect.top + parent.scrollTop + selectRect.height,
                        parentName: parent.className
                    };
                }
            }
            return this.getPropertiesBasedOnParent(element.parentNode);
        } else {
            return false;
        }
    };

    createSelectMenu(isMob) {
        const {options, menuOpened} = this.state;
        const {t, renderInPortal} = this.props;
        let widthOfMenu = 0, menuLeft = 0, menuTop = 0;
        if (this.select) {
            let selectProperties = this.getPropertiesBasedOnParent(this.select);
            if (!!selectProperties) {
                widthOfMenu = selectProperties.widthOfMenu;
                menuLeft = selectProperties.menuLeft;
                menuTop = selectProperties.menuTop;
            } else {
                let rect = this.select.getBoundingClientRect();
                widthOfMenu = rect.width;
                menuLeft = rect.left;
                menuTop = rect.top + rect.height + window.scrollY;
            }
        }
        return (
            <Fade in={menuOpened} unmountOnExit onExited={this.onExited} onExit={this.onExit}>
                <div className={this.getSelectMenuClassName(isMob, renderInPortal)}
                     style={!isMob && renderInPortal ? {width: widthOfMenu, left: menuLeft, top: menuTop} : {}}
                     ref={ref => this.selectContainer = ref} tabIndex={1}>
                    {
                        !isMob &&
                        <>
                            {options}
                            {
                                options.length === 0 &&
                                <p className="empty-list"><i>{t("select.noRecords")}</i></p>
                            }
                        </>
                    }
                </div>
            </Fade>
        )
    }

    render() {
        const {search, menuOpened, options} = this.state;
        const {error, showIconOnErrorOnWarning, warning, disabled, clearButton, renderInPortal, autofocus} = this.props;
        let placeholder = this.getPlaceholder();
        let isMob = isMobile();
        let selectProperties = this.getPropertiesBasedOnParent(this.select);
        return (
            <div className={isMob ? "fetura-select mobile" : "fetura-select"} ref={ref => this.select = ref}>
                <Input type="search" value={search} onChange={this.onSearchChange}
                       disabled={disabled} placeholder={placeholder} readOnly={isMob} error={error} warning={warning}
                       onFocus={disabled ? null : this.onFocusInput} onBlur={this.onBlurInput} autofocus={autofocus}
                       onKeyDown={this.onKeyDownInput}/>
                <div className="fetura-select-icons">
                    <i className={menuOpened ? "fas fa-caret-up pointer" : "fas fa-caret-down pointer"}
                       onClick={isMob ? null : this.onCaretClick} tabIndex={0} ref={this.caret}/>
                </div>
                {
                    isMob &&
                    <select onChange={this.onMobileSelectChange} value={this.getMobileSelectValue()}
                            ref={this.mobileSelect} disabled={disabled}>
                        <option/>
                        {options}
                    </select>
                }
                {
                    clearButton &&
                    <div className="fetura-select-icons clear-button">
                        <i className="fas fa-times pointer" onClick={this.onRemoveValueClick}/>
                    </div>
                }
                {
                    renderInPortal && !selectProperties && ReactDOM.createPortal(this.createSelectMenu(isMob), document.getElementById("root") || document.createElement("div"))
                }
                {
                    renderInPortal && !!selectProperties && !!document.getElementsByClassName(selectProperties.parentName)[0] &&
                    ReactDOM.createPortal(this.createSelectMenu(isMob), document.getElementsByClassName(selectProperties.parentName)[0] || document.createElement("div"))
                }
                {
                    showIconOnErrorOnWarning && (error || warning) &&
                    <Tooltip tooltipContent={error || warning} type={error ? "error" : "warning"}>
                        <div className="input-icon">
                            <i className={error ? "fas fa-exclamation-circle error" : "fas fa-exclamation-circle warning"}/>
                        </div>
                    </Tooltip>
                }
            </div>
        );
    }

}

Select.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // nazwa pola z obiektu
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]).isRequired
    })).isRequired,
    onChange: PropTypes.func,
    onSearchChange: PropTypes.func,
    error: PropTypes.string,
    showIconOnErrorOnWarning: PropTypes.bool,
    warning: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]).isRequired,
    placeholder: PropTypes.string,
    clearButton: PropTypes.bool,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    renderInPortal: PropTypes.bool,
    initialSearch: PropTypes.string,
    autofocus: PropTypes.bool,
    insertFirstOnNoSelected: PropTypes.bool,
};

Select.defaultProps = {
    onChange: () => {
    },
    onSearchChange: () => {
    },
    placeholder: "",
    clearButton: true,
    onBlur: () => {
    },
    renderInPortal: true,
    insertFirstOnNoSelected: true
};

export default withTranslation()(Select);