import React from "react";
import "./_redux-button-picker.scss"

export default class ReduxButtonPicker extends React.Component {

    onClick(value) {
        this.props.input.onChange(value);
    }

    render() {
        const {children, input: {value}} = this.props;
        console.log(this.props);
        return (
            <div className="button-picker">
                {
                    children.map((child, index) =>
                        React.cloneElement(child, {
                            ...child.props,
                            key: index,
                            buttonColor: value === child.props.value ? "success" : "",
                            onClick: () => this.onClick(child.props.value)
                        }, child.props.children)
                    )
                }
            </div>
        );
    }

}