import React from "react";
import PropTypes from "prop-types"
import {myID} from "../../../../libs/generateID";
import ReduxSlider from "../ReduxSlider";

export default class ReduxLabeledSlider extends React.Component {


    render() {
        const {label, input: {value}, valueFormatter, required} = this.props;
        return (
            <React.Fragment>
                <label>{label}{required && <sup>*</sup>}
                    <strong style={{float: "right"}}>{valueFormatter(value)}</strong>
                </label>
                <ReduxSlider {...this.props} valueFormatter={valueFormatter}/>
            </React.Fragment>

        );
    }

}

ReduxLabeledSlider.propTypes = {
    id: PropTypes.string,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]).isRequired,
    isRange: PropTypes.bool,
    disabled: PropTypes.bool,
    valueFormatter: PropTypes.func,
    tipFormatter: PropTypes.func,
    showButtons: PropTypes.bool
};

ReduxLabeledSlider.defaultProps = {
    onChange: (value) => {
    },
    id: myID(),
    disabled: false,
    step: 1,
    min: 0,
    max: 100,
    valueFormatter: (value) => value,
    showButtons: false
};
