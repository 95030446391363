import React from "react";
import {Col, Fade, Row} from "react-bootstrap";
import NavMobileItem from "../../navigation-left-bar/NavMobileItem";
import {connect} from "react-redux";
import {ReactSVG} from "react-svg";
import logoSmall from "../../../resources/images/logos/logo.svg";
import NoConnection from "../../no-connection/NoConnection";
import NoConnectionTooltipContent from "../../no-connection/NoConnectionTooltipContent";
import Offline from "../../offline/Offline";
import InfoBox from "../../basics/info-box/InfoBox";
import Badge from "../../badge/Badge";
import {bodyLockScroll, bodyUnlockScroll, ScrollLock} from "../../../utils/DOMUtils";
import VersionFooter from "../version-footer/VersionFooter";
import {withTranslation} from "react-i18next";

@connect((store) => {
    return {
        farm: store.location.farm,
        farmMaps: store.settings.farmMap,
        farms: store.farms.farms
    };
})

class MobileLeftBar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showMenu: false,
            online: true
        }
    }


    handleEnter = () => {
        this.setState({
            showMenu: true
        })
    };
    handleExit = () => {
        this.setState({
            showMenu: false
        })
    };

    onOnlineChange = online => {
        this.setState({
            online
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.showMenu !== prevProps.showMenu) {
            if (this.state.showMenu) {
                bodyLockScroll(ScrollLock.LEFT_BAR);
            } else {
                bodyUnlockScroll(ScrollLock.LEFT_BAR);
            }
        }
    }

    renderActionButton = ({icon, name, action}) => (
        <Col xl={2} lg={3} md={4} sm={6} xs={12} onClick={action}>
            <i className={`fa-fw ${icon}`}/>
            <a>{name}</a>
        </Col>
    );

    componentWillUnmount() {
        bodyUnlockScroll(ScrollLock.LEFT_BAR);
    }


    render() {
        const {farmChooser, devicesMenuItems, breedingMenuItems, bottomMenuItems, showBadge, footer, t, generalMenuItems} = this.props;
        const {online, showMenu} = this.state;
        return (
            <React.Fragment>
                <div className="left-bar-mobile" onClick={this.handleEnter}>
                    <ReactSVG className={`logo-container`} src={logoSmall}
                              loading={() => <svg viewBox="0 0 270.93334 270.93334"/>}/>
                    <Badge show={showBadge}/>
                    <NoConnection/>
                    <Offline onOnlineChange={this.onOnlineChange}/>
                </div>
                <Fade in={showMenu} unmountOnExit>
                    <div className="left-bar-mobile-menu justify-content-center">
                        <div className="left-bar-mobile-menu-close" onClick={this.handleExit}>
                            <i className="fas fa-times"/>
                        </div>
                        <div>
                            <NoConnectionTooltipContent/>
                        </div>
                        {
                            !online &&
                            <InfoBox boxColor={"error"}>{t("leftBar.noConnection")} <i
                                className="fas fa-wifi-slash"/></InfoBox>
                        }
                        <Col xs={12} className="left-bar-mobile-menu-pos">
                            <Row className="justify-content-center mb-3">
                                <h5>{(t("general"))}</h5>
                            </Row>
                            <Row>
                                {
                                    generalMenuItems.map((item, index) => {
                                        if (!item.hide) {
                                            return (
                                                <NavMobileItem key={`m_${index}`} {...item}
                                                               handleHide={this.handleExit}
                                                               handleShow={this.handleEnter}/>
                                            )
                                        } else {
                                            return null
                                        }
                                    })
                                }
                            </Row>
                            <Row className="justify-content-center mb-3">
                                <h5>{(t("devices"))}</h5>
                            </Row>
                            <Row>
                                {
                                    devicesMenuItems.map((deviceItem, dIndex) => {
                                        if (!deviceItem.hide) {
                                            return (
                                                <NavMobileItem key={`m_${dIndex}`} {...deviceItem}
                                                               handleHide={this.handleExit}
                                                               handleShow={this.handleEnter}/>
                                            )
                                        } else {
                                            return null
                                        }
                                    })
                                }
                            </Row>
                            <Row className="justify-content-center m-3">
                                <h5>{t("breeding")}</h5>
                            </Row>
                            <Row>
                                {
                                    breedingMenuItems.map((breedingItem, bIndex) => {
                                        if (!breedingItem.hide) {
                                            return (
                                                <NavMobileItem key={`m_${bIndex}`} {...breedingItem}
                                                               handleHide={this.handleExit}
                                                               handleShow={this.handleEnter}/>
                                            )
                                        } else {
                                            return null
                                        }
                                    })
                                }
                            </Row>
                            <hr/>
                            <Row>
                                {
                                    bottomMenuItems.map((bottomItem, bIndex) => {
                                        if (!bottomItem.hide) {
                                            return (
                                                <NavMobileItem key={`m_${bIndex}`} {...bottomItem}
                                                               handleHide={this.handleExit}
                                                               handleShow={this.handleEnter}/>
                                            )
                                        } else {
                                            return null
                                        }
                                    })
                                }
                            </Row>
                            <Row>
                                {
                                    farmChooser.map(menuItem => {
                                        if (!menuItem.hide) {
                                            return <NavMobileItem {...menuItem} onClick={this.onClick}/>
                                        }
                                    })
                                }
                                {
                                    footer.map(item => this.renderActionButton(item))
                                }
                            </Row>
                        </Col>
                        <VersionFooter/>
                    </div>
                </Fade>
            </React.Fragment>
        );
    }
}

export default withTranslation()(MobileLeftBar);

