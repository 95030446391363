import React from "react";
import {connect} from "react-redux";
import ReduxLabeledSelect from "../select/labeled-select/ReduxLabeledSelect";
import PropTypes from "prop-types"
import {getEmployees} from "../../../actions/userActions";
import {withTranslation} from "react-i18next";
import * as UserTypes from "validators-schema/Api/constants/userTypes";

@connect(state => ({
    user: state.user.user,
    employees: state.user.employees,
    fetching: state.user.fetching,
    fetched: state.user.fetched
}))
class OperatorChooser extends React.Component {

    createOptions(props) {
        let options = [];
        options.push({
            name: props.t("eventGrid.me"),
            value: props.user.LocalUserID
        });
        return [...options, ...props.employees.filter(item => item.LocalUserID !== props.user.LocalUserID).map(employee => {
            return {
                name: employee.userName,
                value: employee.LocalUserID
            }
        })];
    }

    componentDidMount() {
        this.props.input.onChange(this.props.user.LocalUserID);
        if (this.checkUserType() && !this.props.fetched) {
            this.props.dispatch(getEmployees(this.props.user.ClientID))
        }
    }

    checkUserType(props = this.props) {
        return props.user.UserType === UserTypes.SERVICE || props.user.UserType === UserTypes.OWNER
    }

    render() {
        if (!this.checkUserType()) return null;
        const options = this.createOptions(this.props);
        // TODO loading
        return (
            <div className="operator-chooser">
                <ReduxLabeledSelect options={options} {...this.props}/>
            </div>
        );
    }

}

export default withTranslation()(OperatorChooser)

OperatorChooser.propTypes = {
    label: PropTypes.node.isRequired
};