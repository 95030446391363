import PureSlider, {createSliderWithTooltip, Range as PureRange} from 'rc-slider';
import React from "react";
import PropTypes from "prop-types"
import {first, isArray, isEqual, isNil, last} from "lodash";
import "./_slider.scss"
import Button from "../button/Button";
import {Col, Row} from "react-bootstrap";

const RCSlider = createSliderWithTooltip(PureSlider);
const RCRange = createSliderWithTooltip(PureRange);

const SideType = {
    LEFT: 0,
    RIGHT: 1
};

const ChangeRate = {
    SLOW: 200,
    MEDIUM: 100,
    FAST: 50
};

class Slider extends React.Component {

    holdStart = null;
    timeout = null;
    type = null;

    constructor(props) {
        super(props);
        this.state = {
            value: this.getValue(props)
        };
    }

    getValue = (props) => {
        const {isRange, min, max, value} = props;
        let newValue;
        const checkSingleValue = (v) => {
            let tmp;
            if (isNil(v)) {
                tmp = min;
            } else {
                tmp = Math.min(Math.max(v, min), max);
            }
            return tmp
        };
        if (isRange) {
            newValue = [checkSingleValue(value ? value[0] : null), checkSingleValue(value ? value[1] : null)];
        } else {
            newValue = checkSingleValue(value);
        }
        return newValue;
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!isEqual(nextProps.value, this.state.value) || (nextProps.min !== this.props.min) || (nextProps.max !== this.props.max) || (nextProps.step !== this.props.step)) {
            const newValue = this.getValue(nextProps);
            this.onChange(newValue);
            this.onAfterChange(newValue);
        }
    }

    onChange = newValue => {
        const {state: {value}} = this;
        if (!isEqual(newValue, value)) {
            this.setState({
                value: newValue
            })
        }
    };

    onAfterChange = newValue => {
        const {props: {onChange, value}} = this;
        if (!isEqual(newValue, value)) {
            onChange(newValue);
        }
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    }

    handleButtonClickRight = (event, side) => {
        event.persist();
        const {value} = this.state;
        const {step, onChange, max, min} = this.props;
        if (isArray(value)) {
            let newValue = value.slice(0);
            if (side === SideType.LEFT) {
                let toChange = +(first(newValue) + step).toFixed(2);
                newValue.shift();
                newValue.unshift(toChange);
            } else {
                let toChange = +(last(newValue) + step).toFixed(2);
                newValue.pop();
                newValue.push(toChange);
            }
            if (last(newValue) <= max && first(newValue) <= max && first(newValue) >= min && last(newValue) >= min && !isEqual(newValue, value)) {
                this.setState({
                    value: newValue
                }, () => onChange(newValue));
            } else {
                this.onTouchEnd();
            }
        } else {
            let newValue = +(value + step).toFixed(2);
            if (newValue <= max && newValue >= min && !isEqual(newValue, value)) {
                this.setState({
                    value: newValue
                }, () => onChange(newValue));
            } else {
                this.onTouchEnd();
            }
        }
    };

    handleButtonClickLeft = (event, side) => {
        event.persist();
        const {value} = this.state;
        const {step, onChange, max, min} = this.props;
        if (isArray(value)) {
            let newValue = value.slice(0);
            if (side === SideType.LEFT) {
                let toChange = +(first(newValue) - step).toFixed(2);
                newValue.shift();
                newValue.unshift(toChange);
            } else {
                let toChange = +(last(newValue) - step).toFixed(2);
                newValue.pop();
                newValue.push(toChange);
            }
            if (last(newValue) <= max && first(newValue) <= max && first(newValue) >= min && last(newValue) >= min && !isEqual(newValue, value)) {
                this.setState({
                    value: newValue
                }, () => onChange(newValue));
            } else {
                this.onTouchEnd();
            }
        } else {
            let newValue = +(value - step).toFixed(2);
            if (newValue <= max && newValue >= min && !isEqual(newValue, value)) {
                this.setState({
                    value: newValue
                }, () => onChange(newValue));
            } else {
                this.onTouchEnd();
            }
        }
    };

    getTimeoutTime() {
        let currentTime = +new Date();
        if (currentTime - this.holdStart < 1000 * 2) return ChangeRate.SLOW;
        if (currentTime - this.holdStart < 1000 * 4) return ChangeRate.MEDIUM;
        return ChangeRate.FAST;
    }

    startTimeout(event, type) {
        this.timeout = setTimeout(() => {
            if (this.type === "+") {
                this.handleButtonClickRight(event, type);
            } else if (this.type === "-") {
                this.handleButtonClickLeft(event, type);
            }
            this.startTimeout(event, type);
        }, this.getTimeoutTime());
    }

    onTouchStart(event, type) {
        this.holdStart = +new Date();
        this.startTimeout(event, type);
    }

    onIncrementTouchStart = (event, type) => {
        this.type = "+";
        this.onTouchStart(event, type)
    };

    onDecrementTouchStart = (event, type) => {
        this.type = "-";
        this.onTouchStart(event, type);
    };

    onTouchEnd = () => {
        this.holdStart = null;
        this.type = null;
        clearTimeout(this.timeout);
    };

    disableContextMenu = e => {
        e.preventDefault();
        return false;
    };

    renderButtons = (props) => {
        const {isRange, disabled, max, min} = props;
        const {value} = this.state;
        let leftSide = <Col>
            <Row className="justify-content-center mr-1">
                <Button className="left" buttonStyle="bordered" icon={<i className="fas fa-minus"/>}
                        onClick={e => this.handleButtonClickLeft(e, SideType.LEFT)} disabled={disabled || value <= min}
                        onTouchStart={e => this.onDecrementTouchStart(e, SideType.LEFT)}
                        onTouchEnd={this.onTouchEnd} onContextMenu={this.disableContextMenu}
                        onMouseDown={e => this.onDecrementTouchStart(e, SideType.LEFT)}
                        onMouseUp={this.onTouchEnd} onMouseLeave={this.onTouchEnd}
                        type="button"
                />
            </Row>
        </Col>;

        let rightSide = <Col>
            <Row className="justify-content-center ml-1">
                <Button className="right" buttonStyle="bordered" icon={<i className="fas fa-plus"/>}
                        onClick={e => this.handleButtonClickRight(e, SideType.RIGHT)}
                        disabled={disabled || value >= max}
                        onTouchStart={e => this.onIncrementTouchStart(e, SideType.RIGHT)}
                        onTouchEnd={this.onTouchEnd} onContextMenu={this.disableContextMenu}
                        onMouseUp={this.onTouchEnd} onMouseLeave={this.onTouchEnd}
                        onMouseDown={e => this.onIncrementTouchStart(e, SideType.RIGHT)}
                        type="button"
                />
            </Row>
        </Col>;

        let leftSideRange = <Col>
            <Row className="flex-column justify-content-center mr-1">
                <Button className="right" buttonStyle="bordered" icon={<i className="fas fa-plus"/>}
                        onClick={e => this.handleButtonClickRight(e, SideType.LEFT)}
                        disabled={disabled || value[0] >= max}
                        onTouchStart={e => this.onIncrementTouchStart(e, SideType.LEFT)}
                        onTouchEnd={this.onTouchEnd} onContextMenu={this.disableContextMenu}
                        onMouseDown={e => this.onIncrementTouchStart(e, SideType.LEFT)}
                        onMouseLeave={this.onTouchEnd} onMouseUp={this.onTouchEnd}
                        type="button"
                />
                <Button className="left" buttonStyle="bordered" icon={<i className="fas fa-minus"/>}
                        onClick={e => this.handleButtonClickLeft(e, SideType.LEFT)}
                        disabled={disabled || value[0] <= min}
                        onTouchStart={e => this.onDecrementTouchStart(e, SideType.LEFT)}
                        onTouchEnd={this.onTouchEnd} onContextMenu={this.disableContextMenu}
                        onMouseDown={e => this.onDecrementTouchStart(e, SideType.LEFT)}
                        onMouseUp={this.onTouchEnd} onMouseLeave={this.onTouchEnd}
                        type="button"
                />
            </Row>
        </Col>;

        let rightSideRange = <Col>
            <Row className="flex-column justify-content-center ml-1">
                <Button className="right" buttonStyle="bordered" icon={<i className="fas fa-plus"/>}
                        onClick={e => this.handleButtonClickRight(e, SideType.RIGHT)}
                        disabled={disabled || value[1] >= max}
                        onTouchStart={e => this.onIncrementTouchStart(e, SideType.RIGHT)}
                        onTouchEnd={this.onTouchEnd} onContextMenu={this.disableContextMenu}
                        onMouseDown={e => this.onIncrementTouchStart(e, SideType.RIGHT)}
                        onMouseUp={this.onTouchEnd} onMouseLeave={this.onTouchEnd}
                        type="button"
                />
                <Button className="left" buttonStyle="bordered" icon={<i className="fas fa-minus"/>}
                        onClick={e => this.handleButtonClickLeft(e, SideType.RIGHT)}
                        disabled={disabled || value[1] <= min}
                        onTouchStart={e => this.onDecrementTouchStart(e, SideType.RIGHT)}
                        onTouchEnd={this.onTouchEnd} onContextMenu={this.disableContextMenu}
                        onMouseDown={e => this.onDecrementTouchStart(e, SideType.RIGHT)}
                        onMouseUp={this.onTouchEnd} onMouseLeave={this.onTouchEnd}
                        type="button"
                />
            </Row>
        </Col>;

        if (!!isRange) return [leftSideRange, rightSideRange];
        return [leftSide, rightSide];
    };

    render() {
        const {disabled, isRange, valueFormatter, tipFormatter} = this.props;
        const {value} = this.state;
        const buttons = this.renderButtons(this.props);
        //         v        - poprawka prezycji sliderów gdy mamy floaty zeby ustawiać krancowe przedziały
        let {max, min, step = 1} = this.props;
        if (!Number.isInteger(step)) {
            max = max && max + step / 100;
            min = min && min - step / 100;
        }
        //         ^        - koniec poprawki
        return (
            <div className="fetura-slider">
                {
                    buttons[0]
                }
                {
                    !!isRange &&
                    <RCRange
                        onChange={this.onChange}
                        onAfterChange={this.onAfterChange}
                        disabled={disabled}
                        min={min}
                        max={max}
                        value={value}
                        tipFormatter={tipFormatter}
                        step={step}
                    />
                }
                {
                    !isRange &&
                    <RCSlider
                        onChange={this.onChange}
                        onAfterChange={this.onAfterChange}
                        disabled={disabled}
                        min={min}
                        max={max}
                        value={value}
                        tipFormatter={valueFormatter}
                        step={step}
                    />
                }
                {
                    buttons[1]
                }
            </div>
        )
    }

}

Slider.propTypes = {
    onChange: PropTypes.func,
    id: PropTypes.string,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]).isRequired,
    isRange: PropTypes.bool,
    disabled: PropTypes.bool,
    valueFormatter: PropTypes.func,
    tipFormatter: PropTypes.func
};

Slider.defaultProps = {
    onChange: (value) => {
    },
    // id: myID(),
    disabled: false,
    step: 1,
    min: 0,
    max: 100,
    isRange: false,
    valueFormatter: (value) => value,
    tipFormatter: value => value
};

export default Slider
