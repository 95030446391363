import React from "react";
import PropTypes from "prop-types";
import "./_mobile-info.scss";
import Hammer from "hammerjs"

export default class MobileInfo extends React.Component {

    constructor(props) {
        super(props);

        this.container = React.createRef();
    }

    componentDidMount() {
        let container = this.container.current;
        if (container) {
            let mc = new Hammer(container);
            mc.get("swipe").set({
                direction: Hammer.DIRECTION_RIGHT
            });
            mc.on("swiperight", () => {
                this.props.onHide();
            })
        }
    }

    getClassName() {
        const {show} = this.props;
        let className = "fetura-mobile-info";
        if (show) className += " show";
        return className;
    }

    render() {
        const {children, onHide, name} = this.props;
        return (
            <div className={this.getClassName()} ref={this.container}>
                <div className="fetura-mobile-info-header">
                    <i className="fas fa-chevron-left pointer" onClick={onHide}/>
                    <h4>{name}</h4>
                </div>
                {children}
            </div>
        );
    }

}

MobileInfo.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
    name: PropTypes.string
}