import React, { Component } from 'react';
import windowSize from 'react-window-size';
import _ from "lodash"

/**
 * Komponent sluzy do przekazywania wielkosci okna do childrenow
 */
class ScreenSize extends Component {

    renderChildren = () => {
        const {children, windowWidth, windowHeight} = this.props;
        if(!children){
            return null
        }else if(_.isFunction(children)){
            return children({windowWidth, windowHeight});
        }else {
            return React.cloneElement(children, {windowWidth, windowHeight}, children.props.children);
        }
    };

    render() {
        return (
            <>
              {this.renderChildren()}
            </>
        );
    }

}

export default windowSize(ScreenSize);