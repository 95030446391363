import React from "react";
import PropTypes from "prop-types";
import {myID} from "../../../libs/generateID";
import "./_radio.scss";

export default class Radio extends React.Component {

    onChange = e => {
        this.props.onChange(e.target.checked, this.props, e);
    };

    render() {
        const {label, id = myID(), disabled, checked, readOnly, required, divRef, name, value} = this.props;
        return (
            <div className={"fetura-radio"} ref={divRef}>
                <input
                    disabled={disabled}
                    type="radio"
                    id={id}
                    onChange={this.onChange}
                    checked={checked}
                    readOnly={readOnly}
                    name={name}
                    value={value}
                />
                <label
                    style={readOnly ? {pointerEvent: 'none'} : {}}
                    htmlFor={id}
                >
                    &nbsp;{label}{required && <sup>*</sup>}
                </label>
            </div>
        )
    }
}

Radio.propTypes = {
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    id: PropTypes.string,
    readOnly: PropTypes.bool,
    disable: PropTypes.bool,
    required: PropTypes.bool,
    divRef: PropTypes.object,
    name: PropTypes.string,
    value: PropTypes.string
};

Radio.defaultProps = {
    onChange: () => {},
    required: false,
    disabled: false
};