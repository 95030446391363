import React from "react";
import Select from "./Select";
import PropTypes from "prop-types";

export default class ReduxSelect extends React.PureComponent {

    onChange = value => {
        this.props.input.onChange(value);
    };

    onBlur = value => {
        this.props.input.onBlur(value);
    }

    render() {
        const {showIconOnErrorOnWarning, meta: {touched, error}, options, input: {value}, clearButton} = this.props;
        return (
            <Select
                options={options}
                onChange={this.onChange}
                showIconOnErrorOnWarning={showIconOnErrorOnWarning}
                error={touched && error ? error : ""}
                value={value}
                clearButton={clearButton}
                onBlur={this.onBlur}
            />
        );
    }

}

ReduxSelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // nazwa pola z obiektu
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]).isRequired
    })).isRequired,
    showIconOnErrorOnWarning: PropTypes.bool
};
