import React from "react";
import Card from "../card/Card";
import "./_row-component.scss";
import PropTypes from "prop-types";

export default class RowComponent extends React.Component {

    onClick = (e) => {
        this.props.onClick();
    };

    render() {
        const {children, fixedWidth, className, color, inactive, onContextMenu, style} = this.props;
        let cName = 'fetura-row';
        cName += fixedWidth ? ' fixed-width' : '';
        cName += " " + className;
        cName += color ? ` ${color}` : '';
        cName += inactive ? " inactive" : "";
        return (
            <Card style={style} className={cName} type={"container"} colorDensity={50} onClick={this.onClick}
                  onContextMenu={onContextMenu}>
                {children}
            </Card>
        );
    }
}


RowComponent.propTypes = {
    children: PropTypes.node.isRequired,
    fixedWidth: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.string,
    type: PropTypes.oneOf(["basic", "list"]),
    inactive: PropTypes.bool,
    onContextMenu: PropTypes.func
};

RowComponent.defaultProps = {
    onClick: () => {
    },
    className: "",
    color: "",
    type: "basic",
    inactive: false
};
