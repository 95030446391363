import React from "react";
import PropTypes from "prop-types"
import _ from "lodash";
import {isMobile} from "../../../utils/MobileUtils";

export default class SelectItem extends React.PureComponent {

    onSelect = () => {
        this.props.onSelect(this.props.option)
    };

    getText = () => {
        if (this.props.option.name) return _.get(typeof this.props.option.value === 'object' ? this.props.option.value : this.props.option, this.props.option.name, this.props.option.name || "");
        if (typeof this.props.option.value === "object") return "";
        return this.props.option.value || "";
    };

    onMouseEnter = () => {
        this.props.onHoverChange(this.props.index)
    };

    getClassName() {
        const {active, hovered} = this.props;
        let className = "select-item";
        if (active) className += " active";
        if (hovered) className += " hovered";
        return className;
    }

    render() {
        const {index} = this.props;
        if (isMobile()) return (
            <option value={index}>{this.getText()}</option>
        );
        return (
            <div className={this.getClassName()} onClick={this.onSelect}
                 onMouseEnter={this.onMouseEnter}>
                {this.getText() || ""}
            </div>
        );
    }

}

SelectItem.propTypes = {
    option: PropTypes.shape({
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // nazwa pola z obiektu
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]).isRequired
    }),
    onSelect: PropTypes.func,
    active: PropTypes.bool,
    index: PropTypes.number,
    hovered: PropTypes.bool,
    onHoverChange: PropTypes.func
};

SelectItem.defaultProps = {
    onSelect: () => {
    },
    active: false,
    hovered: false,
    onHoverChange: () => {
    }
};