import React from "react";
import {isMobile} from "../../../utils/MobileUtils";
import MobileLeftBar from "./MobileLeftBar";
import {Link, withRouter} from "react-router-dom";
import {ReactSVG} from "react-svg";
import logoSmall from "../../../resources/images/logos/logo.svg";
import Clock from "../../Clock";
import NoConnection from "../../no-connection/NoConnection";
import Button from "../button/Button";
import MainNavLeftBar from "../../navigation-left-bar/MainNav";
import PropTypes from "prop-types";
import {withCookies} from "react-cookie";
import Menu from "../menu/Menu";
import Api from "../../../localization/ApiLanguage";
import MenuItem from "../menu/MenuItem";
import {hideShowTreeLeftSideBar} from "../../../actions/locationAction";
import {logout} from "../../../utils/UserUtils";
import "./_left-bar.scss"
import {connect} from "react-redux";
import Badge from "../../badge/Badge";
import Offline from "../../offline/Offline";
import {Roles} from "../../../constans/roles";
import VersionFooter from "../version-footer/VersionFooter";
import {withTranslation} from "react-i18next";
import {fetchLanguage} from "../../../actions/localizationActions";


export class SideBar extends React.Component {

    constructor(props) {
        super(props);

        const {cookies} = props;
        let expanded = cookies.get("expanded");
        this.state = {
            expanded: expanded !== undefined ? expanded === "true" : true,
            event: undefined,
            noConnection: {loading: true}
        }
    }

    getLanguages = () => {
        let list = [];
        Api.getLanguage().map((lang) => {
                if (lang.lang !== this.props.selectedLang) {
                    if (process.env.REACT_APP_STAGE === "local" || process.env.REACT_APP_STAGE === "development" || process.env.REACT_APP_STAGE === "staging" || this.props.user.UserType === "V") {
                        list.push(
                            <MenuItem key={lang.lang} onClick={() => this.switchLanguage(lang.lang)}>
                                {lang.lang.toUpperCase()}
                            </MenuItem>)
                    } else {
                        if (lang.lang !== "cimode") {
                            list.push(
                                <MenuItem key={lang.lang} onClick={() => this.switchLanguage(lang.lang)}>
                                    {lang.lang.toUpperCase()}
                                </MenuItem>)
                        }
                    }
                }
            }
        );
        return list;
    };

    handleOnClick = () => {
        const {dispatch} = this.props;
        dispatch(hideShowTreeLeftSideBar(""));
    };

    switchLanguage = async (lang) => {
        const {dispatch, i18n} = this.props;
        await i18n.changeLanguage(lang);
        await dispatch(fetchLanguage(lang));
    };

    logout = () => {
        logout();
    };

    onExpandClick = () => {
        const {expanded} = this.state;
        const {cookies} = this.props;
        cookies.set("expanded", !expanded);
        this.setState({
            expanded: !expanded
        });
    };

    onLangClick = (e) => {
        console.log(e);
        e.persist();
        this.setState({
            event: e
        })
    };

    footerData = () => {
        return [
            {
                action: this.onLangClick,
                icon: "fas fa-language",
                name: this.props.t("language")
            },
            {
                action: this.logout,
                icon: "fas fa-power-off",
                name: this.props.t("logout")
            }
        ];
    };

    renderFooter = (data = []) => {
        let buttonProps = {type: "button", buttonColor: "white", className: "round", buttonStyle: "text"};
        return <div className={"footer"}>
            <div className="footer-buttons">
                {data.map(item =>
                    <Button {...buttonProps} onClick={item.action} icon={<i className={item.icon}/>}/>
                )}
            </div>
        </div>
    };

    render() {
        const {expanded, event} = this.state;
        const {showBadge, menuItems, showPing, devicesMenuItems, breedingMenuItems, bottomMenuItems, user: {attributes: {login}}, generalMenuItems} = this.props;
        const farmChooser = [
            {
                icon: "fas fa-list",
                locations: ["farmChooser"],
                href: "/farmChooser",
                name: this.props.t("leftBar.farmChooser"),
                disRedirect: true,
                isShowing: "farm",
                roles: [Roles._GENERAL_READ]
            },
        ];
        if (isMobile()) return (
            <>
                <MobileLeftBar
                    showBadge={showBadge}
                    devicesMenuItems={devicesMenuItems}
                    breedingMenuItems={breedingMenuItems}
                    bottomMenuItems={bottomMenuItems}
                    farmChooser={farmChooser}
                    footer={this.footerData()}
                    generalMenuItems={generalMenuItems}
                />
                <Menu event={event}>
                    {this.getLanguages()}
                </Menu>
            </>
        );
        return (
            <div className={`left-bar ${expanded ? "expanded" : ""}`}>
                <div className="header">
                    <Link to="/" id="logo-link" onClick={this.handleOnClick}>
                        <ReactSVG className={`logo-container ${showBadge ? 'show-badge' : ''}`} src={logoSmall}
                                  loading={() => <svg viewBox="0 0 270.93334 270.93334"/>}/>
                        {expanded && <Clock format={'HH:mm:ss'}/>}
                        {
                            showPing &&
                            <>
                                <NoConnection/>
                                <Badge show={showBadge}/>
                                <Offline/>
                            </>
                        }
                    </Link>
                    <div className={"expand"}>
                        <Button type={"button"} onClick={this.onExpandClick}
                                icon={<i className={"fas fa-bars"}/>} buttonStyle={"text"}/>
                    </div>
                </div>
                <div className={"fetura-user"}>
                    <i className="fa fa-user" aria-hidden="true"/>
                    <b>{login}</b>
                </div>
                <div className="menu">
                    <div className="align-self-center">
                        <MainNavLeftBar menuItems={menuItems} expanded={expanded}/>
                        <hr/>
                        <MainNavLeftBar menuItems={farmChooser} expanded={expanded}/>
                    </div>
                </div>
                {
                    this.renderFooter(this.footerData())
                }
                <VersionFooter/>
                <Menu event={event}>
                    {this.getLanguages()}
                </Menu>
            </div>
        );
    }

}

SideBar.propTypes = {
    menuItems: PropTypes.array.isRequired,
    showBadge: PropTypes.bool,
    showPing: PropTypes.bool
};

SideBar.defaultProps = {
    showPing: true
};

SideBar = connect(state => ({
    selectedLang: state.language.locale,
    farm: state.location.farm,
    user: state.user,
}))(SideBar);
SideBar = withCookies(SideBar);
SideBar = withRouter(SideBar);

export default withTranslation()(SideBar);

